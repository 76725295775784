<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="flex animate-redraw flex-col pb-16">
    <div
      v-if="showRemovedVendorMessage"
      class="typo-body mb-6 mt-4 flex rounded-lg border border-semantic-notice-100 bg-semantic-notice-50 p-3 font-medium text-grey-900"
    >
      <BaseIcon icon="warning" class="mr-3 shrink-0 text-semantic-notice-600" />
      <span>{{
        $t("request_flow.vendors_removed_info", { count: removedVendorCount })
      }}</span>
    </div>
    <div v-if="vendors.length > 0 || pending" class="mb-4">
      <h2 class="typo-h5">
        <template v-if="locationsLoading">{{
          $t("request_flow.locations_loading")
        }}</template>
        <template v-else-if="!isMultiLocation">
          {{
            $t("request_flow.which_vendors_in_address_would_you_like", {
              address: locations[0]?.address,
            })
          }}
        </template>
        <template v-else>
          {{ $t("request_flow.select_vendors_near") }}
          <select
            v-model="payload.location_id"
            class="typo-body my-2 inline max-w-[100%] text-ellipsis whitespace-nowrap rounded-lg border border-grey-300 px-2.5 py-1 text-grey-700 md:my-0 md:mt-1 md:max-w-[40%]"
          >
            <option
              v-for="location in locations as OgApi.Location[]"
              :key="location.id"
              :value="location.id"
            >
              {{ location.address }}&nbsp;({{ location.zip }})
            </option>
          </select>
          {{ $t("request_flow.you_would_like_offers_from") }}
        </template>
      </h2>
      <RequestFlowComponentsSlidesComponentsVendorAmountInfo
        :amount="selectedVendorsAmount"
        :max-amount="MAX_AMOUNT_VENDORS"
        :available-vendors-amount="vendors.length"
      />
    </div>
    <template v-for="vendor in vendors" :key="vendor.route_key as string">
      <RequestFlowComponentsSlidesComponentsVendorItem
        :vendor="vendor"
        :is-selected="isSelected(vendor)"
        :disabled="!isSelected(vendor) && maxSelected"
        class="mb-4"
        @toggle-selection="toggleVendorSelection(vendor)"
      />
    </template>
    <div v-if="noVendors">
      <img :src="noVendorsFoundLogo" alt="" loading="lazy" />
      <h3 class="typo-body mt-6 block font-semibold text-grey-600">
        {{
          $t("request_flow.no_vendors_deliver_to_address", {
            address: selectedLocation?.address,
          })
        }}
      </h3>
      <div class="typo-body mt-2 text-grey-500">
        {{ $t("request_flow.dont_hesitate_to_call") }}
      </div>
      <div class="typo-body mt-2 text-grey-500">
        <a href="tel:+4543991529" class="text-brand-600"
          >{{ $t("request_flow.call_us") }} +45 4399 1529</a
        >
        {{ $t("request_flow.or") }}
        <a :href="supportChatUrl" class="text-brand-600">{{
          $t("request_flow.go_to_chat_support")
        }}</a>
      </div>
      <div v-if="isMultiLocation" class="mt-16">
        <h3 class="typo-h5">
          {{
            $t(
              "request_flow.we_might_have_vendors_that_deliver_to_other_location",
            )
          }}
        </h3>
        <select
          v-model="payload.location_id"
          class="typo-body mt-4 inline max-w-[40%] text-ellipsis whitespace-nowrap rounded-lg border border-grey-300 px-2.5 py-1 text-grey-700"
        >
          <option
            v-for="location in locations as OgApi.Location[]"
            :key="location.id"
            :value="location.id"
          >
            {{ location.address }}&nbsp;({{ location.zip }})
          </option>
        </select>
      </div>
    </div>
    <RequestFlowComponentsSlidesComponentsFloatingButtons
      key="vendor-selection-floating-buttons"
      :disable-next="!hasVendorSelected"
      :next-text="nextButtonText"
      @back="emit('back')"
      @next="emit('next')"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ref, computed, watchEffect, watch } from "vue";
import noVendorsFoundLogo from "~/assets/no-vendors-found.svg?url";
import { useUserStore } from "~/store/UserStore";
const { t: $t } = useI18n();

const MAX_AMOUNT_VENDORS = 3;

interface Props {
  serviceId: string;
  vendorIds?: string[];
  payload: OgApi.TaskPayload;
  isActive: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits([
  "selected",
  "next",
  "back",
  "update:partner-ids",
  "skip-slide",
]);
const supportChatUrl = `${
  useRuntimeConfig().public.customerAppUrl
}conversations/support`;

const { locations, locationsLoading } = storeToRefs(useUserStore());
const selectedLocation = computed(() => {
  return locations.value.find((loc) => loc.id === props.payload.location_id);
});

// Local reactive state for partner IDs
const localPartnerIds = ref([...(props.payload.partner_ids || [])]);

// Watch for changes from the parent prop and update local state
watch(
  () => props.payload.partner_ids,
  (newVal) => {
    // Simple comparison; might need deep compare if order matters and changes
    if (JSON.stringify(newVal) !== JSON.stringify(localPartnerIds.value)) {
      localPartnerIds.value = [...(newVal || [])];
    }
  },
  { deep: true },
); // Use deep watch for array changes

const { data: vendorData, pending }: any = await useLazyAsyncData(
  `vendors-full-${
    selectedLocation.value?.zip
      ? selectedLocation.value.zip
      : "vendors-full-all"
  }`,
  () => {
    return $fetch(
      // no need to use the auth token here, since the public endpoint serves the same
      `${useRuntimeConfig().public.apiUrl}public/services/${
        props.serviceId
      }/partners`,
      {
        params: {
          zip: selectedLocation.value?.zip,
          resource: "full", // badges
          ignore_marketplace_visibility: true,
        },
      },
    );
  },
  {
    watch: [selectedLocation],
    transform(raw: any) {
      if (!Array.isArray(raw?.data)) return raw;

      // in case there is a preselected vendor, sort it up, but only initially
      return {
        data: [...raw.data].sort((a, b) => {
          if (isSelected(a) && !isSelected(b)) return -1;
          if (!isSelected(a) && isSelected(b)) return 1;
          return 0;
        }),
      };
    },
  },
);

const vendors = computed<OgApi.VendorFull[]>(() => {
  return vendorData.value?.data || [];
});

const removedVendorCount = ref(0);
const initialVendorCheckDone = ref(false);
const showRemovedVendorMessage = ref(false);
const skipConditionMet = ref(false);

watchEffect(() => {
  if (!Array.isArray(vendors.value) || pending.value) return;

  const currentLocalIds = localPartnerIds.value || [];
  const availableVendorKeys = new Set(vendors.value.map((v) => v.route_key));
  const filteredIds = currentLocalIds.filter((id) =>
    availableVendorKeys.has(id),
  );
  const countAfter = filteredIds.length;

  if (!initialVendorCheckDone.value) {
    const countBefore = currentLocalIds.length;
    removedVendorCount.value = countBefore - countAfter;
    if (removedVendorCount.value > 0) {
      showRemovedVendorMessage.value = true;
    }
    initialVendorCheckDone.value = true;

    if (countAfter === MAX_AMOUNT_VENDORS) {
      skipConditionMet.value = true;
    }
  }

  if (JSON.stringify(filteredIds) !== JSON.stringify(currentLocalIds)) {
    localPartnerIds.value = filteredIds;
    emit("update:partner-ids", filteredIds);
  } else {
    localPartnerIds.value = filteredIds;
  }
});

watch(
  () => props.isActive,
  (newIsActive) => {
    if (newIsActive && skipConditionMet.value) {
      nextTick(() => {
        emit("skip-slide");
      });
      skipConditionMet.value = false;
    }
  },
);

// Base computed properties on localPartnerIds
function isSelected(vendor: OgApi.Vendor) {
  return localPartnerIds.value.includes(vendor.route_key);
}

function toggleVendorSelection(vendor: OgApi.Vendor) {
  if (showRemovedVendorMessage.value) {
    showRemovedVendorMessage.value = false;
  }

  const currentIds = localPartnerIds.value;
  const isCurrentlySelected = currentIds.includes(vendor.route_key);
  let newPartnerIds;

  if (isCurrentlySelected) {
    newPartnerIds = currentIds.filter((id) => id !== vendor.route_key);
  } else {
    if (currentIds.length >= MAX_AMOUNT_VENDORS) {
      return;
    }
    newPartnerIds = [...currentIds, vendor.route_key];
  }
  localPartnerIds.value = newPartnerIds;
  emit("update:partner-ids", newPartnerIds);
}

const isMultiLocation = computed(() => {
  return locations.value.length > 1;
});

const hasVendorSelected = computed(() => {
  return localPartnerIds.value.length > 0;
});

const maxSelected = computed(() => {
  return localPartnerIds.value.length >= MAX_AMOUNT_VENDORS;
});

const selectedVendorsAmount = computed(() => {
  return localPartnerIds.value?.length || 0; // Use local state length
});

const nextButtonText = computed(() => {
  return hasVendorSelected.value
    ? $t("request_flow.select_n_vendors", {
        count: localPartnerIds.value.length,
      })
    : $t("request_flow.select_vendors");
});

const noVendors = computed(() => {
  return !pending.value && vendors.value?.length < 1;
});

watchEffect(() => {
  if (noVendors.value) {
    const posthog = usePosthog();
    posthog.capture("Request flow | No vendors", {
      url: window.location.href,
      serviceId: props.serviceId,
      selectedLocation: selectedLocation.value,
      hasMulti: isMultiLocation.value,
    });
  }
});
</script>

<style>
.vendor-list-enter-active,
.vendor-list-leave-active {
  transition: all 0.5s ease;
}
.vendor-list-enter-from,
.vendor-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
