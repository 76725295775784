<template>
  <button
    v-if="!isSelected"
    type="button"
    class="flex items-center justify-center gap-1 rounded-md border border-solid border-grey-500 bg-warm-neutral-50 px-4 py-[0.25rem] text-body font-normal leading-[1.375rem] text-grey-900 shadow-[0px_2px_12px_0px_rgba(0,0,0,0.20)] hover:border-grey-700 hover:bg-grey-50 focus:outline-1 focus:outline-grey-200 active:border-grey-700 active:bg-grey-100"
    :class="{ 'opacity-30': !isSelected && disabled }"
  >
    {{ t("search.get_offer") }}
  </button>
  <button
    v-else
    type="button"
    class="flex h-8 items-center justify-center gap-1 rounded-md border border-solid border-grey-500 bg-warm-neutral-50 px-4 py-[0.25rem] text-body font-normal leading-[1.375rem] text-grey-900 shadow-[0px_2px_12px_0px_rgba(0,0,0,0.20)] hover:border-grey-700 hover:bg-grey-50 focus:outline-1 focus:outline-grey-200 active:border-grey-700 active:bg-grey-100"
  >
    <BaseIcon icon="checkmark_circle" :size="16" />
  </button>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  isSelected: boolean;
  disabled?: boolean;
}>();
</script>
