interface RequestFlowConfiguration {
  serviceId?: string;
  vendorIds?: string[];
  posthog: {
    trigger: string;
    source: string;
  };
}

export const useUIStore = defineStore("UI", () => {
  const mainNavIsShown = ref(true);
  const mobileNavIsShown = ref(false);
  const footerIsShown = ref(true);
  const zipCode = ref("");
  const isEmbedded = ref(false);
  const mobileCustomerAppNavIsShown = ref(false);
  const requestFlowIsShown = ref(false);
  const requestFlowHeaderIsShown = ref(true);
  const requestFlowConfiguration = ref({} as RequestFlowConfiguration);

  const $route = useRoute();
  isEmbedded.value = Object.prototype.hasOwnProperty.call(
    $route.query,
    "embedded",
  );

  watch([mobileNavIsShown, mobileCustomerAppNavIsShown], (value) => {
    value.some((v) => v) ? disableScroll() : enableScroll();
  });

  watch(requestFlowIsShown, (value) => {
    if (value) setTimeout(() => disableScroll(), 250);
    if (!value) enableScroll();
  });

  function toggleMainNav() {
    mainNavIsShown.value = !mainNavIsShown.value;
  }

  function hideMainNav() {
    mainNavIsShown.value = false;
  }

  function showMainNav() {
    mainNavIsShown.value = true;
  }

  function hideMobileNav() {
    mobileNavIsShown.value = false;
  }

  function showMobileNav() {
    mobileNavIsShown.value = true;
  }

  function toggleMobileNav() {
    mobileNavIsShown.value = !mobileNavIsShown.value;
  }

  function hideMobileCustomerNav() {
    mobileCustomerAppNavIsShown.value = false;
  }

  function showMobileCustomerNav() {
    mobileCustomerAppNavIsShown.value = true;
  }

  function toggleMobileCustomerNav() {
    mobileCustomerAppNavIsShown.value = !mobileCustomerAppNavIsShown.value;
  }

  function showRequestFlow(config: RequestFlowConfiguration) {
    usePosthog().capture("Request flow | Started", {
      url: window.location.href,
      ...config.posthog,
    });

    requestFlowIsShown.value = true;
    requestFlowConfiguration.value = config;
  }

  function hideRequestFlow() {
    requestFlowIsShown.value = false;
  }

  function hideRequestFlowHeader() {
    requestFlowHeaderIsShown.value = false;
  }

  function showRequestFlowHeader() {
    requestFlowHeaderIsShown.value = true;
  }

  function hideFooter() {
    footerIsShown.value = false;
  }

  function showFooter() {
    footerIsShown.value = true;
  }

  const scrollIsEnabled = ref(true);
  function disableScroll() {
    scrollIsEnabled.value = false;
  }

  function enableScroll() {
    scrollIsEnabled.value = true;
  }

  function setZipCode(value: string) {
    zipCode.value = value;
  }

  return {
    mainNavIsShown,
    mobileNavIsShown,
    hideMobileNav,
    showMobileNav,
    toggleMobileNav,
    toggleMainNav,
    hideMainNav,
    showMainNav,
    footerIsShown,
    hideFooter,
    showFooter,
    requestFlowIsShown,
    showRequestFlow,
    hideRequestFlow,
    requestFlowConfiguration,
    requestFlowHeaderIsShown,
    showRequestFlowHeader,
    hideRequestFlowHeader,
    scrollIsEnabled,
    disableScroll,
    enableScroll,
    zipCode,
    setZipCode,
    isEmbedded,
    hideMobileCustomerNav,
    showMobileCustomerNav,
    toggleMobileCustomerNav,
    mobileCustomerAppNavIsShown,
  };
});
