<template>
  <ClientOnly>
    <div
      v-if="service"
      class="fixed inset-0 z-50 flex h-full w-full flex-col overflow-auto bg-white"
      :class="{ 'top-[40px]': isOfficehubImpersonation }"
    >
      <RequestFlowComponentsFlowHeader
        v-show="requestFlowHeaderIsShown"
        :service="service"
        :current="currentSlide"
        :amount-of-slides="amountOfSlides"
        @close="emit('close')"
      />
      <div
        v-if="currentSlide"
        class="-mt-px h-full"
        :class="{ 'h-[calc(100%-80px)]': isOfficehubImpersonation }"
      >
        <div class="flex h-full w-full items-center">
          <RequestFlowComponentsSliderWrapper
            :payload="payload"
            :questions="payload.answers"
            :service-id="serviceId"
            :vendor-ids="vendorIds"
            :service="service"
            @slide-changed="currentSlide = $event"
            @amount-of-slides="amountOfSlides = $event"
            @update:partner-ids="updatePartnerIds"
          />
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useUIStore } from "~/store/UIStore";
import { useLeaveWarning } from "~/utils/use-leave-warning";
import { useTabPrevent } from "~/utils/use-tab-prevent";
import { track } from "~/utils/tracking/tracking";
import { useOfficehub } from "~/utils/use-officehub";

const { isOfficehubImpersonation } = useOfficehub();

const emit = defineEmits(["close"]);
const currentSlide = ref(1);
const amountOfSlides = ref(0);

// get vendor and service information - route takes precedence, then from passed configuration
const { requestFlowHeaderIsShown } = storeToRefs(useUIStore());
const { requestFlowConfiguration } = storeToRefs(useUIStore());
const vendorIds = computed(() => {
  return requestFlowConfiguration.value.vendorIds || [];
});
const serviceId =
  (useRoute().meta.serviceApiId as string | undefined) ||
  (requestFlowConfiguration.value.serviceId as string);

const payload = ref<OgApi.TaskPayload>({
  location_id: null,
  partner_ids: vendorIds.value,
  service_id: serviceId,
  answers: [],
});

function updatePartnerIds(newIds: string[]) {
  payload.value.partner_ids = newIds;
}

onMounted(() => {
  const { activate: activateLeaveWarning } = useLeaveWarning();
  const { activate: activateTabPrevent } = useTabPrevent();
  activateLeaveWarning();
  activateTabPrevent();
});

onUnmounted(() => {
  const { deactivate: deactivateLeaveWarning } = useLeaveWarning();
  const { deactivate: deactivateTabPrevent } = useTabPrevent();
  deactivateLeaveWarning();
  deactivateTabPrevent();

  // reset service data on unmount to prevent initialization issue
  // @see https://www.notion.so/officeguru/Flow-bug-17c50dec4ba68058bd80ca8f07721b71?pvs=4
  serviceData.value = null;
});

const { data: serviceData }: any = await useLazyAsyncData(
  `request-flow-${serviceId}`,
  () => {
    return $fetch(
      // no need to use the auth token here, since the public endpoint serves the same
      `${useRuntimeConfig().public.apiUrl}public/services/${serviceId}`,
    );
  },
);

const service = computed<OgApi.Service | undefined>(
  () => serviceData.value?.data,
);

watch(
  service,
  () => {
    payload.value.service_id = service.value?.id || null;
    if (!service.value?.id || !service.value?.name) return;
    track({
      event: "request_flow_started",
      metadata: {
        service_name: service.value.name,
        service_id: service.value.id,
        type: "service",
        products: vendorIds.value.map((id) => ({
          product_id: id,
        })),
      },
      posthogEvent: {
        name: "Request flow started",
        properties: {
          service_id: service.value.id,
          service_name: service.value.name,
        },
      },
    });
  },
  { immediate: true },
);

const questions = computed<Array<OgApi.ServiceQuestion> | undefined>(
  () => service.value?.questions,
);
// write the questions to the payload w. an empty slot for an answer
// and a few modifications to please the backend
watch(
  questions,
  () => {
    payload.value.answers =
      questions.value?.map((question) => ({
        ...question,
        answer: question.type === "composite" ? [] : null,
        question_id: question.id,
        id: undefined,
      })) || [];
  },
  { immediate: true },
);
</script>
