<template>
  <div
    v-if="badgesFormatted.length || (tags && tags.length > 0)"
    class="flex flex-wrap gap-1"
  >
    <BaseBadge
      v-for="(badge, index) in badgesFormatted.slice(0, badgesToShowCount)"
      :key="index"
      :text="badge?.text || ''"
      :icon-name="badge?.iconName"
      :icon-size="16"
      :color="badge?.key === 'full_capacity' ? 'yellow' : 'blue'"
    />
    <template v-for="tag in tags?.slice(0, tagsToShowCount)" :key="tag.id">
      <BaseBadge :text="tag.name" color="grey" />
    </template>
    <BaseTooltip
      v-if="hiddenCount > 0 && hiddenTagsTooltip"
      :text="hiddenTagsTooltip"
      anchor-top
    >
      <BaseBadge :key="'hidden-count'" :text="'+' + hiddenCount" color="grey" />
    </BaseTooltip>
  </div>
</template>

<script setup lang="ts">
import BaseTooltip from "~/components/base-tooltip.vue";
const { t: $t } = useI18n();

interface Props {
  badges: OgApi.VendorBadges;
  tags?: { id: number; name: string }[];
  fullCapacity?: boolean;
  displayMax?: number;
}

const props = withDefaults(defineProps<Props>(), {
  tags: () => [],
  displayMax: Infinity,
});

interface Badge {
  iconName: BaseIconName;
  text: string;
  key: string;
}

interface Badges {
  in_high_demand?: Badge;
  responds_fast?: Badge;
  top_vendor?: Badge;
  full_capacity?: Badge;
}

const badgesFormatted = computed(() => {
  const badges: Badges = {
    in_high_demand: {
      key: "high_demand",
      iconName: "high_demand",
      text: $t("badges.high_demand"),
    },
    responds_fast: {
      key: "fast_response",
      iconName: "fast_response",
      text: $t("badges.fast_response"),
    },
    top_vendor: {
      key: "top_vendor",
      iconName: "top_vendor",
      text: $t("badges.top_vendor"),
    },
    full_capacity: {
      key: "full_capacity",
      iconName: "shopping_cart_no",
      text: $t("badges.full_capacity"),
    },
  };

  const formatted = Object.keys(props.badges)
    .filter((key) => props.badges[key as keyof typeof badges])
    .map((key) => badges[key as keyof typeof badges])
    .filter((badge) => !!badge);

  if (props.fullCapacity) {
    formatted.push(badges.full_capacity);
  }
  return formatted;
});

const tagsCount = computed(() => props.tags?.length || 0);
const badgesCount = computed(() => badgesFormatted.value.length);
const totalItemsCount = computed(() => badgesCount.value + tagsCount.value);
const hiddenCount = computed(() =>
  Math.max(0, totalItemsCount.value - props.displayMax),
);
const badgesToShowCount = computed(() =>
  Math.min(badgesCount.value, props.displayMax),
);
const tagsToShowCount = computed(() =>
  Math.max(
    0,
    Math.min(tagsCount.value, props.displayMax - badgesToShowCount.value),
  ),
);

const hiddenTagNames = computed(() => {
  if (hiddenCount.value > 0 && tagsCount.value > tagsToShowCount.value) {
    const hiddenTags = props.tags?.slice(tagsToShowCount.value) || [];
    return hiddenTags.map((tag) => tag.name.replace(/ /g, "\u00A0"));
  }
  return [];
});

const hiddenTagsTooltip = computed(() => {
  return hiddenTagNames.value.length > 0
    ? hiddenTagNames.value.join("\n")
    : null;
});
</script>
