import { markRaw } from "vue";
import { Router } from "vue-router";
import { PageRenderer } from "#components";
import SingleService from "~/pages/single-service.vue";
import SingleVendor from "~/pages/single-vendor.vue";
import SingleBlog from "~/pages/single-blog.vue";
import SingleProduct from "~/pages/components/single-product.vue";
import SingleVendorProductsList from "~/pages/single-vendor-products-list.vue";
import { linkName } from "~/utils/linking";

const handleMap: { [key: string]: Component } = {
  services: markRaw(SingleService),
  vendors: markRaw(SingleVendor),
  blogs: markRaw(SingleBlog),
  products: markRaw(SingleProduct),
  productList: markRaw(SingleVendorProductsList),
  cmsPage: markRaw(PageRenderer),
};

export default defineNuxtPlugin(async (nuxtApp) => {
  const router = nuxtApp.$router as Router;
  const config = nuxtApp.$config as any;

  (await getRoutesFromCms(config)).forEach((o: CmsCollectionProp) => {
    const isService = o.collection.handle === "services";
    const isVendor = o.collection.handle === "vendors";
    const isProduct = o.collection.handle === "products";

    const route = {
      name: generateName(o),
      path: o.url,
      component: handleMap[o.collection.handle] || handleMap.cmsPage,
      meta: {
        apiUrl: o.api_url,
        ...(isService ? { serviceApiId: o.api_id, serviceSlug: o.slug } : {}),
        ...(isVendor ? { vendorApiId: o.api_id, vendorSlug: o.slug } : {}),
        ...(isProduct
          ? {
              productApiId: o.api_id,
              productSlug: o.slug,
              partnerApiId: o.partner_api_id,
            }
          : {}),
      },
    };
    router.addRoute("routes", route);

    if (isVendor) {
      const productListRoute = {
        name: `vendors-${o.slug}-products`,
        path: `${o.url}/${getLocalizedRoutePath("products")}`,
        component: handleMap.productList,
        meta: {
          apiUrl: o.api_url,
          vendorApiId: o.api_id,
          vendorSlug: o.slug,
        },
      };
      router.addRoute("routes", productListRoute);
    }
  });

  router.addRoute("routes", {
    name: "index",
    path: "/",
    redirect: `/${globalThis.lang}`,
  } as any);

  router.addRoute("routes", {
    name: "search",
    path: getLocalizedRoutePath("search"),
    redirect(to: any) {
      return { name: "discovery vendors", params: to.params };
    },
  });

  router.addRoute("routes", {
    name: "discovery",
    path: getLocalizedRoutePath("discovery"),
    component: () => import("~/pages/single-discovery.vue"),
    redirect(to: any) {
      return { name: "discovery vendors", params: to.params };
    },
    children: [
      {
        name: "discovery products",
        path: getLocalizedRoutePath("discoveryProducts"),
        component: () =>
          import("~/pages/components/discovery/single-discovery-products.vue"),
      },
      {
        name: "discovery services",
        path: getLocalizedRoutePath("discoveryServices"),
        component: () =>
          import("~/pages/components/discovery/single-discovery-services.vue"),
      },
      {
        name: "discovery vendors",
        path: getLocalizedRoutePath("discoveryVendors"),
        component: () =>
          import("~/pages/components/discovery/single-discovery-vendors.vue"),
      },
    ],
  } as any);

  router.addRoute("routes", {
    name: "cart",
    path: getLocalizedRoutePath("cart"),
    component: () => import("~/pages/single-cart.vue"),
  });

  router.addRoute("routes", {
    name: "checkout",
    path: getLocalizedRoutePath("checkout"),
    component: () => import("~/pages/single-checkout.vue"),
  });
});

async function getRoutesFromCms(config: any): Promise<CmsCollectionProp[]> {
  const data = await useNuxtApp().runWithContext(() => {
    const url = `${config.public.cmsUrl}api/collections-aggregated`;
    // eslint-disable-next-line no-console
    if (import.meta.server) console.log(`Requesting ${url}`);
    return useFetch(url);
  });

  const tree = data.data.value as Record<string, CmsCollectionProp[]>;

  // no blogs in english || german, so also no route generation
  if (globalThis.lang !== "da") tree.blogs = [];

  return Object.values(tree).flat();
}

function generateName(page: any) {
  // name for resources are generated like this, to make it easier to link to things with just their slug
  if (page.collection.handle === "pages") return page.url;
  return linkName(page.collection.handle, page.slug);
}

export function getLocalizedRoutePath(
  type:
    | "search"
    | "cart"
    | "checkout"
    | "products"
    | "discovery"
    | "discoveryVendors"
    | "discoveryProducts"
    | "discoveryServices",
) {
  const paths = {
    search: {
      en: "/en/search",
      da: "/da/soeg",
      de: "/de/suche",
      de_en: "/de_en/search",
    },
    cart: {
      en: "/en/cart",
      da: "/da/kurv",
      de: "/de/warenkorb",
      de_en: "/de_en/cart",
    },
    checkout: {
      en: "/en/checkout",
      da: "/da/kasse",
      de: "/de/kasse",
      de_en: "/de_en/checkout",
    },
    products: {
      en: "products",
      da: "produkter",
      de: "produkte",
      de_en: "products",
    },
    discovery: {
      en: "/en/discover",
      da: "/da/udforsk",
      de: "/de/entdecken",
      de_en: "/de_en/discover",
    },
    discoveryVendors: {
      en: "/en/discover/vendors",
      da: "/da/udforsk/leverandoerer",
      de: "/de/entdecken/partner",
      de_en: "/de_en/discover/vendors",
    },
    discoveryProducts: {
      en: "/en/discover/products",
      da: "/da/udforsk/produkter",
      de: "/de/entdecken/produkte",
      de_en: "/de_en/discover/products",
    },
    discoveryServices: {
      en: "/en/discover/services",
      da: "/da/udforsk/services",
      de: "/de/entdecken/services",
      de_en: "/de_en/discover/services",
    },
  };

  return paths[type][globalThis.lang as "en" | "da" | "de" | "de_en"];
}
