<template>
  <div itemscope itemtype="https://schema.org/Organization" class="pt-20">
    <BaseContent content-classes="!py-6 md:!py-10 lg:!py-10">
      <div v-if="isLoading" class="h-screen">
        <!-- Prevent collapsing page when loading -->
      </div>
      <template v-else-if="vendor">
        <SingleVendorHeader :vendor="vendor" class="mb-4" />
        <SingleVendorImages
          v-if="
            (vendor?.work_images && vendor.work_images?.length) ||
            vendor.video?.info?.ready_to_stream
          "
          class="mb-10"
          :images="vendor.work_images"
          :vendor="vendor as OgApi.VendorFull"
          :vendor-name="vendor.name"
        />
        <div v-else class="h-6" />
        <div class="grid grid-cols-3 gap-14">
          <div
            class="col-span-3 lg:col-span-2"
            :class="{ 'order-last lg:order-none': REQUEST_FLOW_ACTIVATED }"
          >
            <AnchorList
              class="mb-8"
              :items="anchors as Anchor[]"
              @scroll-to="smoothScrollToElement"
            />
            <div v-if="vendor.description" ref="aboutRef" class="flex flex-col">
              <h2 class="typo-h5 mb-6 font-bold text-grey-900">
                {{ $t("vendor_page.about") }}
              </h2>
              <ExpandableDescription
                class="!border-b-0"
                :description="vendor.description"
              />
            </div>
            <SingleVendorOverview :vendor="vendor as OgApi.VendorFull" />
            <SingleVendorProducts
              v-if="products && products.data && products.data.length"
              :vendor="vendor as OgApi.VendorFull"
              :products="products as OgApi.Webshop.ProductListResponse"
            />
            <SingleVendorServices
              v-if="vendor?.services?.length"
              ref="servicesRef"
              :vendor="vendor as OgApi.VendorFull"
            />
            <SingleVendorOperationalAreas
              v-if="vendor?.zip_ranges?.length"
              ref="operationalAreasRef"
              :vendor="vendor"
            />
            <FaqList
              v-if="faq.length"
              ref="faqRef"
              :items="faq"
              class="!py-10"
            />
            <SingleVendorRatings
              ref="ratingsRef"
              :vendor="vendor as OgApi.VendorFull"
              class="mb-10"
            />
            <SingleVendorReviews
              v-if="vendor?.reviews?.length"
              :vendor="vendor as OgApi.VendorFull"
            />
            <SingleVendorVerificationAndPayment
              v-if="cmsValues?.link_terms_of_payment"
              class="mt-10"
              :vendor="vendor"
              :payment-terms-link="cmsValues?.link_terms_of_payment"
            />
          </div>
          <template v-if="!REQUEST_FLOW_ACTIVATED">
            <div class="hidden lg:block">
              <WrappedContactForm
                v-if="cmsValues?.link_customer_signup"
                class="sticky top-32"
                :vendor="vendor"
                sticky
                :create-account-link="cmsValues.link_customer_signup"
              />
            </div>
          </template>
          <template v-else>
            <div class="col-span-3 block md:col-span-2 lg:col-span-1">
              <GetOfferBox
                sticky
                :services="getOfferBoxServices"
                :vendor-name="vendor.name"
                :response-time="vendor.badges?.typical_response_time"
                posthog-location="vendor_page"
              />
            </div>
          </template>
        </div>
      </template>
    </BaseContent>
    <template v-if="!REQUEST_FLOW_ACTIVATED && vendor">
      <MobileWrappedContactForm
        v-if="cmsValues?.link_customer_signup"
        class="block lg:hidden"
        :vendor="vendor"
        :create-account-link="cmsValues.link_customer_signup"
      />
    </template>
  </div>
</template>
<script setup lang="ts">
import { Ref, ref, computed, watch, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import FaqList from "~/pages/components/faq-list.vue";
import WrappedContactForm from "~/pages/components/wrapped-contact-form.vue";
import MobileWrappedContactForm from "~/pages/components/mobile-wrapped-contact-form.vue";
import SingleVendorHeader from "~/pages/components/vendor/single-vendor-header.vue";
import SingleVendorImages from "~/pages/components/vendor/single-vendor-images.vue";
import SingleVendorServices from "~/pages/components/vendor/single-vendor-services.vue";
import ExpandableDescription from "~/pages/components/expandable-description.vue";
import AnchorList from "~/pages/components/anchor-list.vue";
import SingleVendorOverview from "~/pages/components/vendor/single-vendor-overview.vue";
import SingleVendorOperationalAreas from "~/pages/components/vendor/single-vendor-operational-areas.vue";
import SingleVendorRatings from "~/pages/components/vendor/single-vendor-ratings.vue";
import SingleVendorVerificationAndPayment from "~/pages/components/vendor/single-vendor-verification-and-payment.vue";
import SingleVendorReviews from "~/pages/components/vendor/single-vendor-reviews.vue";
import SingleVendorProducts from "~/pages/components/vendor/single-vendor-products.vue";
import { useCMSStore } from "~/store/CMSStore";
import GetOfferBox from "~/pages/components/get-offer-box.vue";

type VendorApiResponse = OgApi.VendorFull | null;
type ProductsApiResponse = OgApi.Webshop.ProductListResponse | null;
type CmsSeoResponse = { data?: { seo?: Partial<SEO> } } | null;

const $route = useRoute();
const { t } = useI18n();
const { cmsValues } = useCMSStore();
const REQUEST_FLOW_ACTIVATED =
  cmsValues?.enable_request_flow || $route.query.rf;

const vendorApiId = computed(
  () => $route.meta.vendorApiId as string | undefined,
);
const vendorApiUrl = computed(() => $route.meta.apiUrl as string | undefined);

if (!vendorApiId.value && import.meta.server) {
  throw createError({
    statusCode: 404,
    statusMessage: "Vendor ID missing from route metadata on server",
    fatal: true,
  });
}

const vendorResult = useAsyncData<VendorApiResponse>(
  `vendor-${vendorApiId.value}`,
  async () => {
    const id = vendorApiId.value;
    if (!id) return null;
    const response = await $fetch<{ data: OgApi.VendorFull }>(
      `${useRuntimeConfig().public.apiUrl}public/partners/${id}`,
      {
        params: { resource: "full" },
      },
    );
    return response.data ?? null;
  },
);

const productsResult = useAsyncData<ProductsApiResponse>(
  `products-${vendorApiId.value}`,
  async () => {
    const id = vendorApiId.value;
    if (!id) return null;
    return await $fetch<OgApi.Webshop.ProductListResponse>(
      `${useRuntimeConfig().public.apiUrl}public/partners/${id}/products`,
    );
  },
);

const seoResult = useAsyncData<CmsSeoResponse>(
  `seo-${vendorApiId.value}`,
  async () => {
    const apiUrl = vendorApiUrl.value;
    if (!apiUrl) return null;
    return await $fetch<CmsSeoResponse>(`${apiUrl}?fields=data.seo`);
  },
);

const {
  data: vendor,
  pending: vendorPending,
  refresh: refreshVendor,
} = vendorResult;
const { data: products, refresh: refreshProducts } = productsResult;
const { data: seoFetchResult, refresh: refreshSeo } = seoResult;

const isLoading = computed(() => vendorPending.value);

watch(vendorApiId, (newId, oldId) => {
  if (import.meta.client && newId && newId !== oldId) {
    refreshVendor();
    refreshProducts();
    refreshSeo();
  }
});

const seoConfig = computed((): SEO | undefined => {
  const cmsSeoData = seoFetchResult.value?.data?.seo;
  if (typeof cmsSeoData !== "object" || cmsSeoData === null) {
    return undefined;
  }
  return {
    title: cmsSeoData.title ?? "",
    locales: cmsSeoData.locales ?? {},
    locales_absolute: cmsSeoData.locales_absolute ?? {},
    canonical_url: cmsSeoData.canonical_url ?? "",
    ...cmsSeoData,
    description: vendor.value?.description || cmsSeoData.description || "",
  };
});

watchEffect(() => {
  if (seoConfig.value) {
    useSeo(seoConfig.value);
  }
});

const getOfferBoxServices = computed((): OgApi.Service[] => {
  if (!vendor.value?.services) return [];
  return vendor.value.services.map((service) => {
    let mappedContent: OgApi.Service["content"] = {};
    if (service.content && !Array.isArray(service.content)) {
      mappedContent = {
        ...service.content,
        example_menu: service.content.example_menu ?? undefined,
      };
    }

    return {
      ...service,
      content: mappedContent,
      tags: [],
      show_in_request_flow: true,
    };
  });
});

const aboutRef = ref();
const servicesRef = ref();
const operationalAreasRef = ref();
const faqRef = ref();
const ratingsRef = ref();

interface Anchor {
  ref: Ref;
  label: string;
  show: boolean;
  hidden?: boolean;
}

const anchors: ComputedRef<Anchor[]> = computed(() => {
  if (!vendor.value) return [];
  return [
    {
      ref: aboutRef,
      label: t("vendor_page.about"),
      show: !!vendor.value.description,
    },
    {
      ref: servicesRef,
      label: t("vendor_page.services"),
      show: !!vendor.value.services?.length,
    },
    {
      ref: operationalAreasRef,
      label: t("vendor_page.operational_areas"),
      show: !!vendor.value.zip_ranges?.length,
    },
    { ref: faqRef, label: t("vendor_page.faq"), show: !!faq.value.length },
    { ref: ratingsRef, label: t("vendor_page.ratings"), show: true },
  ].filter((anchor: Anchor) => !anchor.hidden);
});

const faq = computed(() => {
  if (!vendor.value) return [];
  const faqData: OgApi.VendorFaq | undefined = vendor.value.faq;
  if (!faqData) return [];
  const faqItems: FaqItem[] = [];
  for (const key in faqData) {
    const cur = key as keyof OgApi.VendorFaq;
    if (!faqData[cur] || cur === "faq_company_sustainability") continue;
    faqItems.push({
      question: t(`vendor_page.${cur}`),
      answer: faqData[cur] || "",
    });
  }
  return faqItems;
});

function smoothScrollToElement(ref: Ref) {
  const OFFSET = 72;
  const element = ref?.value?.$el as HTMLElement;
  if (!element) return;
  const top =
    element.getBoundingClientRect().top + globalThis.pageYOffset - OFFSET;
  globalThis.scrollTo({ top, behavior: "smooth" });
}
</script>
