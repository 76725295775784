<template>
  <SafeNuxtLink
    :to="{ name: vendorLinkName(vendor.slug) }"
    class="rounded-lg border border-grey-200"
  >
    <div
      class="relative flex h-[155px] items-center justify-center rounded-t-lg bg-grey-100 bg-cover bg-center"
      :style="
        getVendorImageUrl(vendor)
          ? `background-image: url('${getVendorImageUrl(vendor)}')`
          : ''
      "
    >
      <div v-if="activeServiceId" class="absolute right-2 top-2">
        <VendorCardButton
          :is-selected="isVendorSelected"
          :disabled="hasReachedMaxVendors"
          @click.prevent="handleGetOffer"
        />
      </div>
      <div
        v-if="rating.count"
        class="absolute left-2 top-2 flex items-center gap-1 rounded-md bg-warm-neutral-200 px-1"
      >
        <span class="typo-body text-grey-900">{{
          Number(rating.score).toFixed(1)
        }}</span>
        <BaseIcon icon="star-full" :size="14"></BaseIcon>
        <span class="typo-body text-grey-500">({{ rating.count }})</span>
      </div>
      <div
        v-if="!getVendorImageUrl(vendor)"
        class="flex flex-col items-center gap-2"
      >
        <BaseIcon icon="picture" :size="14" class="text-grey-500" />
        <p class="typo-caption text-grey-400">Images coming soon!</p>
      </div>
    </div>
    <div class="p-4">
      <p class="typo-body-lg text-grey-900">{{ vendor.name }}</p>
      <div
        v-if="isFrokostService || formattedPriceRange || minPax"
        class="mt-1 flex w-full flex-wrap items-center gap-x-2 gap-y-1"
      >
        <div
          v-if="formattedPriceRange || isFrokostService"
          class="typo-body flex items-center gap-1"
        >
          <BaseIcon icon="discount" :size="14" class="text-grey-500" />
          <span v-if="formattedPriceRange" class="text-grey-500">
            {{ formattedPriceRange }}
          </span>
          <span v-else-if="isFrokostService" class="text-grey-500">
            {{ $t("search.no_price") }}
          </span>
        </div>

        <span
          v-if="isFrokostService || (formattedPriceRange && minPax)"
          class="text-grey-500"
        >
          •
        </span>

        <div
          v-if="minPax || isFrokostService"
          class="typo-body flex items-center gap-1"
        >
          <BaseIcon icon="group" :size="14" class="text-grey-500" />
          <span v-if="minPax" class="text-grey-500">
            {{ $t("search.min_count_pax", { count: minPax }) }}
          </span>
          <span v-else-if="isFrokostService" class="text-grey-500">
            {{ $t("search.no_min_pax") }}
          </span>
        </div>
      </div>
      <div v-if="hasBadges || hasTags" class="mt-4 flex flex-wrap gap-1">
        <VendorBadges :badges="vendor.badges" :tags="tags" :display-max="5" />
      </div>
    </div>
  </SafeNuxtLink>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import VendorCardButton from "./vendor-card/vendor-card-button.vue";
import { vendorLinkName } from "@/utils/linking.ts";
import { useCmsImage } from "~/utils/use-cms-image.ts";
import { useSelectedVendorsStore } from "@/store/SelectedVendorsStore";
import { toast } from "~/utils/toast";
import BaseIcon from "~/components/base-icon.vue";
import { useCurrency } from "~/utils/use-currency.js";
import { SERVICE_PLAIN_TEXT_IDS } from "@/constants/categories-and-services";

const MAX_SELECTED_VENDORS = 3;

const props = defineProps({
  vendor: {
    type: Object,
    required: true,
  },
});
const selectedVendorsStore = useSelectedVendorsStore();
const route = useRoute();
const activeServiceId = computed(() => route.query.service_id || null);

const { formatCurrency } = useCurrency();
const { t } = useI18n();

const serviceMeta = computed(() => {
  if (!props.vendor?.services) return null;
  const servicesArray = Array.isArray(props.vendor.services)
    ? props.vendor.services
    : Object.values(props.vendor.services);
  return servicesArray[0] || null;
});

const minPax = computed(() => {
  return serviceMeta.value?.content?.minimum_pax;
});

const formattedPriceRange = computed(() => {
  const from = serviceMeta.value?.content?.price_from;
  const to = serviceMeta.value?.content?.price_to;

  if (from === null || from === undefined) {
    return null;
  }

  if (to === null || to === undefined) {
    const formattedFrom = formatCurrency(from);
    return t("search.price_from_per_pax", { price: formattedFrom });
  }

  const formattedFromRange = formatCurrency(from, { includeSymbol: false });
  const formattedToRange = formatCurrency(to);
  return t("search.price_range_per_pax", {
    from: formattedFromRange,
    to: formattedToRange,
  });
});

const tags = computed(() => {
  return serviceMeta.value?.tags;
});

const rating = computed(() => {
  return props.vendor?.rating;
});

const hasBadges = computed(() => {
  const badges = props.vendor?.badges;
  const isBadgeObject =
    badges && typeof badges === "object" && !Array.isArray(badges);
  if (!isBadgeObject) {
    return false;
  }
  return Object.values(badges).includes(true);
});

const hasTags = computed(
  () => Array.isArray(tags.value) && tags.value.length > 0,
);

function getVendorImageUrl(vendor) {
  let baseUrl;
  if (vendor.work_images.length) {
    baseUrl = vendor.work_images[0]?.url;
  } else if (vendor.images.logo) {
    baseUrl = vendor.images.logo;
  }
  if (!baseUrl) return null;
  const params = "width=500&height=160&fit=cover";
  return useCmsImage({
    src: baseUrl || "",
    params,
  }).value;
}

const hasReachedMaxVendors = computed(() => {
  if (!activeServiceId.value) return false;
  const selected = selectedVendorsStore.getSelectedVendors(
    activeServiceId.value,
  );
  return selected.length >= MAX_SELECTED_VENDORS && !isVendorSelected.value;
});

function handleGetOffer(event) {
  event.stopPropagation();
  if (!activeServiceId.value) return;

  if (hasReachedMaxVendors.value) {
    toast({
      text: t("search.max_vendors_reached_warning", {
        count: MAX_SELECTED_VENDORS,
      }),
      type: "info",
      duration: 2000,
    });
    return;
  }

  if (isVendorSelected.value) {
    selectedVendorsStore.removeVendor(activeServiceId.value, props.vendor.id);
    return;
  }

  selectedVendorsStore.addVendor(activeServiceId.value, props.vendor);
}

const isVendorSelected = computed(() => {
  if (!activeServiceId.value) return false;
  const selected = selectedVendorsStore.getSelectedVendors(
    activeServiceId.value,
  );
  return selected.some((v) => v.id === props.vendor.id);
});

const isFrokostService = computed(() => {
  return (
    serviceMeta.value?.plain_text_id === SERVICE_PLAIN_TEXT_IDS.FROKOSTORDNING
  );
});
</script>
