import { defineStore } from "pinia";
import { ref } from "vue";

interface Vendor {
  id: string;
  [key: string]: any;
}

interface SelectedVendors {
  [serviceId: string]: Vendor[];
}

export const useSelectedVendorsStore = defineStore("SelectedVendors", () => {
  const selectedVendors = ref<SelectedVendors>({});

  function addVendor(serviceId: string, vendor: Vendor) {
    if (!selectedVendors.value[serviceId]) {
      selectedVendors.value[serviceId] = [];
    }
    if (
      !selectedVendors.value[serviceId].some((v) => v.id === vendor.id) &&
      selectedVendors.value[serviceId].length < 3
    ) {
      selectedVendors.value[serviceId].push(vendor);
    }
  }

  function removeVendor(serviceId: string, vendorId: string) {
    if (!selectedVendors.value[serviceId]) return;
    selectedVendors.value[serviceId] = selectedVendors.value[serviceId].filter(
      (v) => v.id !== vendorId,
    );
  }

  function getSelectedVendors(serviceId: string) {
    return selectedVendors.value[serviceId] || [];
  }

  return {
    selectedVendors,
    addVendor,
    removeVendor,
    getSelectedVendors,
  };
});
