import { toast as toastify } from "vue3-toastify";
import ToastContent from "~/components/toast/toast-content.vue";

type Type = "success" | "error" | "info";
interface Toast {
  type?: Type;
  duration?: number;
  text: string;
  link?: {
    label: string;
    to?: { name: string };
    href?: string;
  };
  id?: string;
}

function toast({ text, type, duration, link, id }: Toast) {
  const defaultType = type || "success";
  const defaultDuration = duration || 5000;
  const defaultId = id || Math.floor(Math.random() * 10000000).toString();
  return toastify(
    ({ toastProps }) =>
      h(
        ToastContent as any,
        {
          toastProps,
          text,
          type: defaultType,
          subLink: link,
        },
        [],
      ),
    {
      type: defaultType,
      icon: false,
      toastClassName: toastStyle(defaultType),
      autoClose: defaultDuration || false,
      toastId: defaultId,
    },
  );
}

function removeToast(id: string) {
  toastify.remove(id);
}

function toastStyle(type?: Type) {
  if (type === "error") {
    return "!bg-semantic-negative-50 !border-semantic-negative-200";
  }

  if (type === "info") {
    return "!bg-semantic-info-50 !border-semantic-info-100";
  }

  return "!bg-semantic-positive-50 !border-semantic-positive-200";
}

export { toast, removeToast };
